<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Tambah Data Tindakan Laboratorium</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="8" md="8" lg="8">
                    <b-form>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                                Nama Kategori <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                :state="checkIfValid('nama_tindakan_laboratorium')"
                                type="text"
                                v-model="$v.is_data.nama_tindakan_laboratorium.$model"
                            ></b-form-input>
                        </b-form-group>
                    </b-form>
                    <hr>
                    <b-form>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Nama Tindakan Lab
                        </template>
                        <b-form-input
                        type="text"
                        v-model="nama_sub_tindakan_lab"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Satuan
                        </template>
                        <Multiselect
                            v-model="satuan_lab"
                            :options="options_satuan"
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            label="nama_satuan_lab"
                            track-by="ms_komponen_tarif_id"
                            placeholder="-- Pilih Satuan --"
                            size="sm"
                        ></Multiselect>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                        Harga Tindakan Lab
                        </template>
                        <b-form-input
                        type="number"
                        v-model="harga_tindakan_lab"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mt-2">
                        <b-button
                            variant="primary"
                            :disabled="!isValid"
                            @click="simpanSub()"
                            >Simpan Tindakan</b-button
                        >
                    </b-form-group>
                    </b-form>
                </b-col>
              </b-row>
  
              <b-row class="mt-2">
                    <b-col cols="12" md="12" lg="12">
                    <b-table
                        :items="is_data.bulk_sub_tindakan_lab"
                        :fields="fields"
                        responsive
                        show-empty
                        small
                        hover
                        ref="table"
                    >
                        <template #cell(actions)="row">
                        <b-button
                            variant="danger"
                            class="mr-1"
                            v-c-tooltip.hover.click="'Hapus Data'"
                            @click="deleteRow(row.item, row.index, $event.target)"
                            ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
                        >
                        <b-button
                            variant="secondary"
                            class="mr-1"
                            v-c-tooltip.hover.click="'Tambah Komponen Tarif'"
                            @click="addKomponen(row.item, row.index, $event.target)"
                            ><CIcon name="cil-pencil" /> {{ row.actions }}</b-button
                        >
                        </template>
                        <template #cell(bulk_tarif_sub_tindakan)="item">
                            <b-row>
                                <b-col>
                                    <ul>
                                        <li v-for="(itemnya, index) in item.item.bulk_tarif_sub_tindakan" :key="index">{{ itemnya.nama_komponen }} : {{ itemnya.harga_pool_tarif_sub_tindakan }}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" offset-md="10" offset-sm="10" offset-lg="10" class="mt-3">
                        <b-button variant="success" @click="simpanFinalData()">Simpan Data</b-button>
                    </b-col>
                </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- MODAL KOMPONEN -->
      <b-modal id="modal-add-komponen" hide-header size="lg" centered title="Tambah Komponen">
        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Komponen Tarif
                        </template>
                        <Multiselect
                            v-model="komponen_tarif"
                            :options="options_komponen"
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            label="nama_komponen_tarif"
                            track-by="ms_komponen_tarif_id"
                            placeholder="-- Pilih Komponen --"
                            size="sm"
                        ></Multiselect>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Harga Tarif Komponen
                        </template>
                        <b-form-input
                            type="number"
                            v-model="harga_pool_tarif_sub_tindakan"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mt-2">
                        <b-button
                            variant="primary"
                            @click="simpanKomponen()"
                            >Simpan Komponen</b-button
                        >
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    :items="bulk_komponen"
                    :fields="fieldsKomponen"
                    responsive
                    show-empty
                    small
                    hover
                >
                <template #cell(actions)="row">
                    <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        @click="deleteRowKomponen(row.item, row.index, $event.target)"
                        ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
                    >
                </template>
                
                </b-table>
            </b-col>
        </b-row>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-add-komponen')">
            Batal
          </b-button>
          <b-button variant="primary" @click="simpan()">Simpan Data</b-button>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "input_tindakan_lab",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        is_data: {
          nama_tindakan_laboratorium: "",
          bulk_sub_tindakan_lab: [],
        },
        nama_sub_tindakan_lab: "",
        harga_tindakan_lab: "",
        komponen_tarif: "",
        harga_pool_tarif_sub_tindakan: "",
        index_komponen: null,
        options_komponen: [],
        satuan_lab: "",
        options_satuan: [],
        bulk_komponen: [],
        fields: [
          {
            key: "nama_sub_tindakan_lab",
            label: "Nama",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "harga_tindakan_lab",
            label: "Harga Tindakan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nama_satuan_lab",
            label: "Satuan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          
          {
            key: "bulk_tarif_sub_tindakan",
            label: "Komponen Tarif",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        fieldsKomponen: [
        {
            key: "nama_komponen",
            label: "Nama Komponen",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "harga_pool_tarif_sub_tindakan",
            label: "Harga Tarif",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        // filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      is_data: {
        nama_tindakan_laboratorium: { required },
      },
    },
    activated(){
        this.totalRows = this.items.length;
      this.getDatas();
 
    },
    async mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.getDatas();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async getDatas(){
        let vm = this
        let komponen_tarif = await vm.$axios.post("/ms_komponen_tarif/list")
        // console.log(komponen_tarif, 'ini komponen');
        vm.options_komponen = komponen_tarif.data.data
        let satuan_lab = await vm.$axios.post("/ms_satuan_lab/list")
        // console.log(satuan_lab, 'log satuan lab');
        vm.options_satuan = satuan_lab.data.data
        // for (let i = 0; i < komponen_tarif.data.data.length; i++) {
        //     let x = komponen_tarif.data.data[i];
        //     let penampung = {}
        //     penampung.value = x.ms_komponen_tarif_id
        //     penampung.text = x.nama_komponen_tarif
        //     vm.options_komponen.push(penampung)
        // }
        // console.log(vm.options_komponen, 'ini options');
      },
      itikiwir(x){
        console.log(x, 'ini x');
      },
      addKomponen(item, index){
        let vm = this
        vm.index_komponen = index
        if(item.bulk_tarif_sub_tindakan){
            vm.bulk_komponen = item.bulk_tarif_sub_tindakan
        }
        vm.$bvModal.show('modal-add-komponen')
      },
      simpan(){
        let vm = this
        vm.is_data.bulk_sub_tindakan_lab[vm.index_komponen].bulk_tarif_sub_tindakan = vm.bulk_komponen
        vm.bulk_komponen = []
        vm.$bvModal.hide('modal-add-komponen')
        this.$refs.table.refresh()
      },
      simpanSub(){
        let vm = this
        let penampung = {}
        console.log(vm.satuan_lab);
        penampung.nama_sub_tindakan_lab = vm.nama_sub_tindakan_lab
        penampung.harga_tindakan_lab = vm.harga_tindakan_lab
        penampung.nama_satuan_lab = vm.satuan_lab.nama_satuan_lab
        penampung.ms_satuan_lab_id = vm.satuan_lab.ms_satuan_lab_id
        vm.is_data.bulk_sub_tindakan_lab.push(penampung)
        vm.nama_sub_tindakan_lab = ""
        vm.harga_tindakan_lab = ""
        vm.satuan_lab = ""
      },
      simpanKomponen(){
        let vm = this
        let penampung = {}
        penampung.nama_komponen = vm.komponen_tarif.nama_komponen_tarif
        penampung.ms_komponen_tarif_id = vm.komponen_tarif.ms_komponen_tarif_id
        penampung.harga_pool_tarif_sub_tindakan = vm.harga_pool_tarif_sub_tindakan
        vm.bulk_komponen.push(penampung)
        vm.komponen_tarif = ""
        vm.harga_pool_tarif_sub_tindakan = ""
      },
      async simpanFinalData(){
        let vm = this
        // console.log(vm.is_data, 'ini datanyaa');
        let reg = await vm.$axios.post("/tindakan_lab/register", vm.is_data)
        console.log(reg, 'ini hasilnyaa');
        if(reg.data.status == 200 && reg.data.message == "sukses"){
            vm.$store.commit('set_alert', {msg: "SUKSES MENDAFTARKAN TINDAKAN LABORATORIUM", variant: "success", showing: true})
            vm.$router.push({
                path: "/laboratorium/tindakan_lab",
                // query: { id_ba : data.item.id },
            }); 
        }
      },
      deleteRow(x,y){
        // console.log(x, y);
        let index = y
        this.is_data.bulk_sub_tindakan_lab.splice(index, 1)
      },
      deleteRowKomponen(x,y){
        // console.log(x, y);
        let index = y
        this.bulk_komponen.splice(index, 1)
      },
    },
  };
  </script>
  